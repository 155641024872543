import { useFormik } from 'formik';
import { InputMask } from 'primereact/inputmask';
import './Forma_zayavka.css';

function FormaZayavka() {
  const validate = (values) => {
    const errors = {};
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!values.firstName) {
      errors.firstName = 'Väli tuleb täita...';
    }
    if (!values.tel) {
      errors.tel = 'Väli tuleb täita...';
    }
    if (!values.email) {
      errors.email = 'Väli tuleb täita...';
    } else if (!re.test(values.email)) {
      errors.email = 'No valide email';
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      tel: '',
      email: '',
      context: '',
    },
    validate,
    onSubmit: async (values) => {
      const data = {
        name: values.firstName,
        tel: values.tel,
        email: values.email,
        msg: values.context,
        time: new Date().toUTCString(),
      };

      let response = await fetch('mail.php', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      let result = await response.text();
      console.log(data);
      values.firstName = '';
      values.tel = '';
      values.email = '';
      values.context = '';
      alert(result);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 id="h2">Täitke vorm ja me helistame teile tagasi</h2>
      {formik.touched.firstName && formik.errors.firstName ? (
        <div
          style={{
            color: '#ffd2d2',
            marginLeft: '12%',
            paddingBottom: '5px',
          }}
        >
          {formik.errors.firstName}
        </div>
      ) : null}
      <input
        className="form__input-item"
        id="firstName"
        name="firstName"
        type="text"
        placeholder="Sinu nimi..."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName}
      />
      {formik.touched.tel && formik.errors.tel ? (
        <div
          style={{
            color: '#ffd2d2',
            marginLeft: '12%',
            paddingBottom: '5px',
          }}
        >
          {formik.errors.tel}
        </div>
      ) : null}

      <InputMask
        id="tel"
        name="tel"
        type="tel"
        mask="+37 999 999 999"
        placeholder="+37___ ___ ___"
        onBlur={formik.handleBlur}
        value={formik.values.tel}
        onChange={formik.handleChange}
      />
      {formik.touched.email && formik.errors.email ? (
        <div
          style={{
            color: '#ffd2d2',
            marginLeft: '12%',
            paddingBottom: '5px',
          }}
        >
          {formik.errors.email}
        </div>
      ) : null}

      <input
        className="form__input-item"
        id="email"
        name="email"
        type="email"
        placeholder="Email..."
        onBlur={formik.handleBlur}
        value={formik.values.email}
        onChange={formik.handleChange}
      />

      <input
        className="form__input-item"
        id="context"
        name="context"
        type="text"
        placeholder="Teenuse nimi..."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.context}
      />
      <div className="form__btn">
        <input
          type="submit"
          className="btn"
          value="Taotlege helistamist"
        ></input>
      </div>
    </form>
  );
}
export default FormaZayavka;

import './Services.css';
import main1 from '../../../img/Services/main7.jpg';
import main4 from '../../../img/Services/clean_sofa.jpg';
import main3 from '../../../img/Services/main3.jpg';
import main2 from '../../../img/Services/clean_window.jpg';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className="main-title">
          <p>Meie teenused ja hinnad</p>
          <h1>
            Pehme mööbli keemiline puhastus, tavapuhastus, üldpuhastus,
            aknapesu, kontoripuhastus, renoveerimisjärgne puhastus...
          </h1>
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>Standartne puhastus</h2>
            {/* <p className="main-services-price">alates ... eek</p> */}
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>Akende puhastus</h2>
            {/* <p className="main-services-price">alates ... eek</p> */}
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2>Puhastus pärast renoveerimist</h2>
            {/* <p className="main-services-price">alates ... eek</p> */}
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <h2>Pehme mööbli keemiline puhastus</h2>
            {/* <p className="main-services-price">alates ... eek</p> */}
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/price">Hinnad...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;

import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import foto1 from '../../img/articles/article1.jpg';
import foto2 from '../../img/articles/article2.jpg';
import foto3 from '../../img/articles/article3.jpg';
import './General_img.css';

function Block() {
  return (
    <div className="block">
      <h3>Химчистка мягкой мебели</h3>
    </div>
  );
}
function BlockOneImgP() {
  return (
    <div>
      <img src={foto1} alt="foto1" className="fotoImg" />
      <p className="special">
        Химчистка мягкой мебели – одно из важнейших мероприятий, связанных с
        поддержанием гигиены в наших домах и на рабочих местах. Мягкая мебель –
        это не только место для отдыха, но и зачастую служит поверхностью для
        работы или приема пищи. Поэтому они собирают много грязи, бактерий и
        клещей, которые могут негативно повлиять на наше здоровье...
      </p>
    </div>
  );
}
function BlockOneCheck() {
  return (
    <div className="check">
      <Link to="/ru/article3">Подробно...</Link>
    </div>
  );
}
function BlockOneFromArticle1() {
  return (
    <div className="block_1">
      <Block />
      <BlockOneImgP />
      <BlockOneCheck />
    </div>
  );
}
function BlockTwo() {
  return (
    <div className="block">
      <h3>Химчистка матрасов</h3>
    </div>
  );
}
function BlockTwoImgP() {
  return (
    <div>
      <img src={foto2} alt="foto9" className="fotoImg" />
      <p className="special">
        Матрас – один из важнейших элементов нашей спальни и в то же время
        место, где мы проводим около трети нашей жизни. Поэтому важно, чтобы наш
        матрас был чистым и гигиеничным. Регулярная стирка матрасов имеет
        решающее значение для поддержания чистоты и гигиены, и в этой статье мы
        представим несколько причин, почему это так важно...
      </p>
    </div>
  );
}
function BlockTwoCheck() {
  return (
    <div className="check">
      <Link to="/ru/article1">Подробно...</Link>
    </div>
  );
}
function BlockTwoFromArticle2() {
  return (
    <div className="block_2">
      <BlockTwo />
      <BlockTwoImgP />
      <BlockTwoCheck />
    </div>
  );
}
function Block3() {
  return (
    <div className="block">
      <h3>Химчистка ковров и ковриков</h3>
    </div>
  );
}
function BlockThreeImgP() {
  return (
    <div>
      <img src={foto3} alt="foto1" className="fotoImg" />
      <p className="special">
        Химчистка ковров и ковриков — важное занятие, которое влияет на чистоту
        и гигиену наших домов. Несмотря на регулярную уборку пылесосом, на этих
        поверхностях могут скапливаться различного рода загрязнения, влияющие не
        только на эстетику, но и на наше здоровье. В этой статье мы представим
        несколько причин, почему стирка ковров и ковриков так важна...
      </p>
    </div>
  );
}
function BlockThreeCheck() {
  return (
    <div className="check">
      <Link to="/ru/article2">Подробно...</Link>
    </div>
  );
}
function BlockOneFromArticle3() {
  return (
    <div className="block_3">
      <Block3 />
      <BlockThreeImgP />
      <BlockThreeCheck />
    </div>
  );
}
function SwiperArticles() {
  return (
    <Swiper
      navigation
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={'auto'}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={{ type: 'fraction' }}
      modules={[EffectCoverflow, Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <BlockTwoFromArticle2 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle3 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle1 />
      </SwiperSlide>
      {/* <SwiperSlide></SwiperSlide> */}
    </Swiper>
  );
}
export default SwiperArticles;

import css from './Whoops404.module.css';
import { Link } from 'react-router-dom';

function Whoops404() {
  return (
    <div className={css.error404}>
      <h1 className={css.nofindresurs}>
        Warning! <span>Указанный путь недействителен или не найден...</span>
      </h1>
      <p>
        Для перехода на сайт нажмите на предоставленную ссылку
        <span className={css.linkpath}>
          <Link to="/"> superclean.ee </Link>
        </span>
      </p>
    </div>
  );
}
export default Whoops404;

import './Important.css';
import expert from '../../../img/important/expert.png';
import quality from '../../../img/important/quality-control.png';
import warning from '../../../img/important/hand.png';
import incurence from '../../../img/important/security_11238130.png';

function Important() {
  return (
    <div className="main__important">
      <h2 className="main-important-h2">
        <span>Miks valida meid!</span>
      </h2>

      <div className="main-important-blocks">
        <div className="main-important-block">
          <img src={expert} alt="expert" className="expert" />
          <h3>Professionaalsus</h3>
          <p>
            Meie kõrgelt kvalifitseeritud spetsialistid tagavad laitmatu
            tulemuse.
          </p>
        </div>
        <div className="main-important-block">
          <img src={quality} alt="expert" className="expert" />
          <h3>Kvaliteet</h3>
          <p>
            Maksimaalse puhtuse saavutamiseks kasutame nüüdisaegseid seadmeid ja
            tõestatud meetodeid.
          </p>
        </div>
        <div className="main-important-block">
          <img src={warning} alt="expert" className="expert" />
          <h3>Individuaalne lähenemine</h3>
          <p>Võttes arvesse iga kliendi ainulaadseid omadusi ja nõudeid</p>
        </div>
      </div>
      <div className="main-important-warning">
        <img src={incurence} alt="incurence" />
        <p>
          Head kliendid, pange tähele, et meie teenused on täielikult
          kindlustatud vastavalt puhastusfirma vastutuskindlustuse programmile.
          See annab teile täiendava kindlustunde meie professionaalsuses ja
          tagab teie huvide turvalisuse ootamatute olukordade korral. Hindame
          teie usaldust ja oleme pühendunud kvaliteetsete teenuste pakkumisele,
          säilitades samal ajal kõrgeimad ohutus- ja töökindlusstandardid.
        </p>
      </div>
    </div>
  );
}
export default Important;

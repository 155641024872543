import { Routes, Route } from 'react-router-dom';
import Whoops404 from './components/4_Whoops404/Whoops404';
import AllProject from './components/AllProject/AllProject';
import About from './components/pagesMenuLinks/About/About';
import Price from './components/pagesMenuLinks/Price/Price';
import Contact from './components/pagesMenuLinks/Contact/Contact';
import Article1 from './components/pagesMenuLinks/Articles/1_Article.jsx';
import Article2 from './components/pagesMenuLinks/Articles/2_Article.jsx';
import Article3 from './components/pagesMenuLinks/Articles/3_Article.jsx';
// ru lang
import AllProjectRu from './LangRu/AllProject/AllProject';
import AboutRu from './LangRu/pagesMenuLinks/About/About';
import PriceRu from './LangRu/pagesMenuLinks/Price/Price';
import ContactRu from './LangRu/pagesMenuLinks/Contact/Contact';
import Article1Ru from './LangRu/pagesMenuLinks/Articles/1_Article';
import Article2Ru from './LangRu/pagesMenuLinks/Articles/2_Article';
import Article3Ru from './LangRu/pagesMenuLinks/Articles/3_Article';

import './App.css';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<AllProject />} />
        <Route path="/about" element={<About />} />
        <Route path="/price" element={<Price />} />
        <Route path="/article1" element={<Article2 />} />
        <Route path="/article2" element={<Article3 />} />
        <Route path="/article3" element={<Article1 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ru/main" element={<AllProjectRu />} />
        <Route path="/ru/about" element={<AboutRu />} />
        <Route path="/ru/price" element={<PriceRu />} />
        <Route path="/contactru" element={<ContactRu />} />
        <Route path="/ru/article1" element={<Article2Ru />} />
        <Route path="/ru/article2" element={<Article3Ru />} />
        <Route path="/ru/article3" element={<Article1Ru />} />
        <Route path="*" element={<Whoops404 />} />
      </Routes>
    </>
  );
}

export default App;

import { MdOutlineEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './Email.css';
function Email() {
  return (
    <div className="email">
      <Link to={'mailto: info@sclean.ee'}>
        <h3 className="email__link">
          <MdOutlineEmail /> info@sclean.ee
        </h3>
      </Link>
    </div>
  );
}
export default Email;

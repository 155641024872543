import { Link } from 'react-router-dom';
import { FiPhoneCall } from 'react-icons/fi';
import Img_phone from '../../../img/links/telephone.png';
import './LinkPhone.css';

export function PhoneOnly() {
  return (
    <div className="header__phone-icons">
      <Link to={'tel:+37256680448'} className="">
        <h3 className="header-phone-item">+372 5668 0448</h3>
      </Link>
    </div>
  );
}

export function PhoneWithIcons() {
  return (
    <div className="header__phone-icons">
      <Link to={'tel:+37256680448'}>
        <FiPhoneCall />
      </Link>
      <Link to={'tel:+37256680448'} className="">
        <h3 className="header-phone-item">+372 5668 0448</h3>
      </Link>
    </div>
  );
}
function Phone() {
  return (
    <div className="header__phone">
      <Link to={'tel:+37256680448'}>
        <img
          title="phone"
          src={Img_phone}
          alt="phone"
          className="header__phone-img"
        />
      </Link>

      <Link to={'tel:+37256680448'} className="header-phone-item-close">
        <h3 className="header-phone-item">+372 5668 0448</h3>
      </Link>
    </div>
  );
}

export default Phone;

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article2.jpg';
import quilon2 from '../../../img/articles/article2.2.jpg';
import { Cataloge } from './1_Article';
import Telegram from '../../../UI/Viber/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>RU</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/article1">ET</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Химчистка матрасов Таллинн</h2>

            <p>
              Матрас – один из важнейших элементов нашей спальни и в то же время
              место, где мы проводим около трети нашей жизни. Поэтому важно,
              чтобы наш матрас был чистым и гигиеничным. Регулярная стирка
              матрасов имеет решающее значение для поддержания чистоты и
              гигиены, и в этой статье мы представим несколько причин, почему
              это так важно.
            </p>
            <p>
              Во-первых, стирка матрасов удаляет такие загрязнения, как пыль,
              клещи, плесень, бактерии и вирусы, которые со временем
              накапливаются в матрасе. Эти загрязнители могут вызывать аллергию,
              астму и другие заболевания, а также влиять на качество сна.
              Поэтому важно регулярно стирать матрас, чтобы удалить эти вредные
              загрязнения.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Во-вторых, стирка матрасов влияет на эстетику и функциональность
              нашего матраса. Чистый матрас выглядит эстетичнее и способствует
              лучшему самочувствию в спальне. Кроме того, стирка матрасов также
              может улучшить комфорт нашего сна, удаляя пятна, запахи и другую
              грязь, которая может повлиять на качество сна.
            </p>
            <p>
              В-третьих, стирка матраса может продлить срок его службы. Матрасы
              – это дорогостоящая инвестиция, поэтому важно позаботиться об их
              долговечности. Регулярная стирка поможет сохранить матрас в
              чистоте и гигиене, а также предотвратить его износ. Благодаря
              этому мы сможем дольше наслаждаться нашим матрасом.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              В-четвертых, стирка матрасов поможет избавиться от неприятных
              запахов. Матрасы часто накапливают запахи пота, еды и других
              источников, которые трудно удалить. Стирка матрасов удаляет эти
              запахи, что положительно влияет на комфорт сна и качество воздуха
              в нашей спальне.
            </p>
            <p>
              Подводя итог, можно сказать, что стирка матрасов чрезвычайно важна
              и должна осуществляться регулярно. Регулярно стирая, мы
              обеспечиваем чистоту и гигиену нашего матраса, улучшаем комфорт
              сна, продлеваем срок службы матраса и предотвращаем образование
              вредных загрязнителей. Стирка матрасов – это простое и эффективное
              решение, которое позволит нам долго наслаждаться матрасом.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article2Ru() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <Quilon />
      <Telegram />
      <Footer />
    </div>
  );
}
export default Article2Ru;

import { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Price.css';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Telegram from '../../../UI/Viber/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="10px">
          <Button onClick={changeLang} select="select">
            <span>RU</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? <Link to="/price">ET</Link> : <Link to="/price">RU</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function PriceNotTable() {
  return (
    <>
      <div className="price">
        <div className="price-title">
          <h1>Цены</h1>
        </div>
        <div className="price__fon">
          <div className="container container-300px">
            <div className="container__title">
              <p>Обращаем ваше внимание, что указанные цены ориентировочные.</p>
              <p>
                Чтобы получить более точное ценовое предложение, необходимо
                познакомиться с объектом и понять с обеих сторон, какие работы
                потребуются. Ваше желание - наше исполнение!
              </p>
            </div>
            <div className="allitems">
              <div className="standartcleaning">
                <h2>Стандартная уборка: </h2>
                <ul>
                  <li>
                    Удаление загрязнений с горизонтальных поверхностей и
                    предметов мебели
                  </li>
                  <li>Влажная уборка пола</li>
                  <li>Влажная очистка плинтусов</li>
                  <li>Влажная очистка радиаторов снаружи</li>
                  <li>Удаление пыли с настольных и напольных светильников</li>
                  <li>Очистка зеркал и стеклянных поверхностей</li>
                  <li>Чистка пылесосом наружных поверхностей мягкой мебели</li>
                  <li>Очистка бытовых приборов снаружи</li>
                  <h3>Уборка кухни:</h3>
                  <li> Влажная чистка фасадов кухонной мебели </li>
                  <li>Влажная уборка столешницы</li>
                  <li>Влажная уборка наружной поверхности холодильника</li>
                  <li>Влажная очистка наружной поверхности печи</li>
                  <li>Влажная уборка плиты </li>
                  <li>Чистка мелких предметов на кухне</li>
                  <li>Чистка раковины</li>
                  <li>Влажная уборка обеденного стола и стульев</li>
                  <li>Очистка штекеров, выключателей и дверей</li>
                  <li>Влажная очистка радиаторов снаружи</li>
                  <li>Влажная уборка подоконников</li>
                  <li>Чистка ковров и полов пылесосом</li>
                  <li>Влажная уборка пола</li>
                  <li>Влажная очистка плинтусов </li>
                  <li>Вынос мусора</li>
                  <li>Удаление видимых пятен со стен </li>
                  <h3> Уборка ванной комнаты и туалета: </h3>
                  <ul>
                    <li>Очистка раковины, ванны и умывальника</li>
                    <li>
                      Очистка стеклянных перегородок в душевых кабинах и ваннах
                    </li>
                    <li>Очистка смесителей</li>
                    <li>Очистка зеркал</li>
                    <li>Очистка наружной поверхности шкафов</li>
                    <li>Очистка штекеров, выключателей и дверей</li>
                    <li> Чистка ковров и полов пылесосом</li>
                    <li>Влажная уборка пола </li>
                    <li> Уборка коридора и прихожей.</li>
                  </ul>
                </ul>
                <div className="about-price">
                  Ориентировочная цена 1.1€ за кв.м.
                </div>
              </div>
              <div className="standartcleaning">
                <h2>Генеральная уборка: </h2>
                <ul>
                  <li>
                    Сухая и влажная уборка горизонтальных поверхностей мебели
                  </li>
                  <li>Удаление видимых пятен со стен </li>
                  <li>Влажная уборка пола</li>
                  <li>Очистка плинтусов</li>
                  <li>Влажная очистка радиаторов </li>
                  <li>Удаление пыли с настольных и напольных светильников</li>
                  <li>Чистка и мойка зеркал и стеклянных поверхностей</li>
                  <li>Очистка розеток, выключателей и дверей</li>
                  <li>Чистка бытовой техники</li>
                  <li>Влажная уборка</li>
                  <li>Мойка окон, оконных рам и внутренних откосов</li>
                  <li>Чистка пылесосом мягкой мебели</li>
                  <li>Удаление пыли с бытовой техники</li>
                  <h3>Ванная комната/туалет: </h3>
                  <li>Чистка и мойка раковины, ванны, смесителей </li>
                  <li>
                    Мойка стеклянных перегородок в душевых кабинах и ваннах
                  </li>
                  <li>Очистка бытовых приборов снаружи</li>
                  <li>Чистка и мойка зеркал</li>
                  <li>Очистка шкафов </li>
                  <li>Влажная очистка воздухоочистителей</li>
                  <li>Очистка штекеров, выключателей и дверей</li>
                  <li>Влажная уборка пола</li>
                  <li>Мойка дверей и откосов </li>
                  <h3>Прихожая/коридор:</h3>
                  <ul>
                    <li>
                      Чистка столов, ящиков, шкафов и других элементов интерьера
                    </li>
                    <li>Влажная уборка пола</li>
                    <li>Влажная очистка плинтусов</li>
                    <li>Очистка зеркал и стеклянных поверхностей</li>
                    <li>Очистка штекеров, выключателей и дверей</li>
                    <li>Чистка пылесосом мягкой мебели</li>
                    <li>Удаление пыли с поверхности ламп</li>
                  </ul>
                </ul>
                <div className="about-price">
                  Ориентировочная цена 2.5€ за кв.м.
                </div>
              </div>
              <div className="standartcleaning">
                <h2>Химчистка мягкой мебели: </h2>
                <ul>
                  <li>Химчистка дивана от 20 €/место</li>
                  <li>Подушки маленькие - 4€</li>
                  <li>Подушки большие-5€</li>
                  <li>Чистка и уход за кожаным диваном от 22 €/место </li>
                  <li>Глубокая чистка коврового покрытия от 1,30 €/м2 </li>
                  <li>Чистка ковров от 10 €/м2</li>
                  <li> Чистка офисных стульев от 10,00 €/шт </li>
                  <li> Чистка текстильного стула - 10€</li>
                  <li>Химчистка матрасов (односпальный) от 55€/шт.</li>
                  <li>Химчистка матрасов (двуспальный) от 75€/шт. </li>
                </ul>
              </div>
              <div className="standartcleaning">
                <h2>Мойка окон: </h2>
                <ul>
                  <li>Мойка оконных стекол внутри и снаружи</li>
                  <li>Очистка оконных рам и уплотнителей</li>
                  <li>Чистка и мойка подоконников</li>
                </ul>
                <div className="pswashwindows">
                  <p>Мытье окон снаружи возможно при температуре до -3 °C.</p>
                  <p>
                    Для достижения наилучших результатов мы рекомендуем
                    использовать сухую погоду.
                  </p>
                  <p>
                    Мы рекомендуем мыть окна не реже 2 раз в год, но в
                    зависимости от расположения здания может потребоваться более
                    частое мытье.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function PriceRu() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <PriceNotTable />
      <Telegram />
      <Footer />
    </div>
  );
}
export default PriceRu;

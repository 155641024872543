import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about.jpg';
import './About.css';
import Telegram from '../../../UI/Viber/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  const closeLang = () => {
    setLang(false);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>ET</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang closeLang={closeLang} isLang={isLang}>
            {isLang ? (
              <Link to="/ru/about">RU</Link>
            ) : (
              <Link to="/about">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function AboutTable() {
  return (
    <div className="about">
      <div className="about-title">
        <h1>Meist</h1>
      </div>
      <div className="container about-container">
        <div className="about__content">
          <div className="about__content-text">
            <h2>Tere, kallid kliendid, oleme puhastusfirma "Super Clean"</h2>
            <p>
              Ettevõte <strong>"Super Clean"</strong> on turul äärmiselt
              olulisel kohal, kuna pakub igapäevaelus vajalikke teenuseid.
              Mööbli puhastamine on kodus keeruline ülesanne, eriti kui seal on
              palju mustust või ohtlikke aineid. Seetõttu tasub kasutada
              professionaalide teenuseid, kes oskavad igat tüüpi mööblit
              käsitleda ja hoolitsevad selle korraliku puhastamise eest.
            </p>
            <p>
              Meie töötame, teie lõõgastuge! Puhas maja tähendab head tuju!
              Super Cleani spetsialistide meeskond tagab teie kodule
              (kinnisvarale) kvaliteetse puhastuse ja korrashoiu.
            </p>
            <p>
              Meie ettevõte on spetsialiseerunud pehme mööbli, sh diivanite,
              toolide, tugitoolide, voodite ja muu pehme mööbli
              professionaalsele puhastamisele. Meie teenused on suunatud nii
              üksikklientidele kui ka ettevõtetele, kes vajavad mööbli
              puhastusteenust kontorites, hotellides, restoranides ja muudes
              avalikes kohtades.
            </p>
            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>
            <p>
              Tõhusa ja ohutu puhastamise tagamiseks kasutame ainult kõrgeima
              kvaliteediga tööriistu ja puhastusvahendeid. Meie töö põhineb
              individuaalsel lähenemisel igale tellimusele, et tagada igale
              mööbliesemele ja kliendile parim tulemus.
            </p>
            <p>
              Meie ettevõte pakub laia valikut teenuseid nagu pindade
              puhastamine, plekieemaldus, lõhna eemaldamine, desinfitseerimine,
              tolmuimeja ja palju muud. Iga teenus on kohandatud vastavalt
              kliendi individuaalsetele vajadustele, et tagada parim võimalik
              tulemus.
            </p>
            <p>
              Pehme mööbli puhastamine võib olla kulukas, kuid meie ettevõte
              pakub konkurentsivõimelisi hindu, mis varieeruvad olenevalt mööbli
              suurusest ja tüübist. Meie hinnad on õiglased ja läbipaistvad, mis
              tähendab, et kliendid ei pea muretsema varjatud kulude pärast.
            </p>
            <p>
              Mööblipuhastusettevõttena oleme pühendunud sellele, et meie
              kliendid oleksid puhtad, turvalised ja meie teenustega rahul.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function About() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <ButtomsLang />
        <AboutTable />
        <Telegram />
        <Footer />
      </div>
    </>
  );
}
export default About;

import './Services.css';
import main1 from '../../../img/Services/main7.jpg';
import main4 from '../../../img/Services/clean_sofa.jpg';
import main3 from '../../../img/Services/main3.jpg';
import main2 from '../../../img/Services/clean_window.jpg';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className="main-title">
          <p>Наши услуги и их стоимость</p>
          <h1>
            Химчистка мягкой мебели, стандартная уборка, генеральная уборка,
            мойка окон, уборка офисных помещений, уборка после ремонта...
          </h1>
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>стандартная уборка</h2>
            {/* <p className="main-services-price">от 50 eek</p> */}
          </div>
          <div className="main__services-item">
            <div className="nain__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>Мойка окон</h2>
            {/* <p className="main-services-price">от 14 eek</p> */}
          </div>
          <div className="main__services-item">
            <div className="nain__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2> уборка после ремонта</h2>
            {/* <p className="main-services-price">от 95 eek</p> */}
          </div>
          <div className="main__services-item">
            <div className="nain__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <h2>Химчистка мягкой мебели</h2>
            {/* <p className="main-services-price">от ... eek</p> */}
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/ru/price">Цены...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;

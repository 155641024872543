import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import LinkSocial from '../../1_Header/SocialLinks/LinkSocial';
import './Contact.css';
import FormaZayavka from '../../Forma/Forma_zayavka';
import Telegram from '../../../UI/Viber/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>RU</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? (
              <Link to="/contact">ET</Link>
            ) : (
              <Link to="/contact">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function ContactTable() {
  return (
    <div className="contact">
      <div className="contacts-title">
        <h1>Контакт</h1>
      </div>
      <div className="container contacts__container">
        <div className="contacts__item">
          <div className="contacts__item-phone">
            <p>Номер телефона:</p>
            <Link to={'tel:+37256680448'}>
              <h2 className="header-phone-item">+372 5668 0448</h2>
            </Link>
          </div>
          <div className="contacts__item-phone">
            <p>Электронный адрес:</p>
            <Link to={'mailto:info@sclean.ee'}>
              <h2 className="header-phone-item"> info@sclean.ee</h2>
            </Link>
          </div>

          <div className="contacts__item-social">
            <p>Мы в социальных сетях:</p>
            <div className="contacts__item-social-links">
              <LinkSocial />
            </div>
          </div>
          <div className="contacts__item-praca">
            {/* <h2></h2> */}
            <p>Работаем по Таллинну и Харьюмаа.</p>
          </div>
          {/* <div className="adress">Õnne tee 4, Rae, Rae, Estonia</div> */}
          <FormaZayavka />
        </div>
      </div>
    </div>
  );
}
function ContactRu() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <ContactTable />
      <Telegram />
      <Footer />
    </div>
  );
}
export default ContactRu;

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article3.jpg';
import quilon2 from '../../../img/articles/article3.3.jpg';
import { Cataloge } from './1_Article';
import Telegram from '../../../UI/Viber/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>RU</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/article2">ET</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Химчистка ковров и ковриков Таллинн</h2>

            <p>
              Химчистка ковров и ковриков — важное занятие, которое влияет на
              чистоту и гигиену наших домов. Несмотря на регулярную уборку
              пылесосом, на этих поверхностях могут скапливаться различного рода
              загрязнения, влияющие не только на эстетику, но и на наше
              здоровье. В этой статье мы представим несколько причин, почему
              стирка ковров и ковриков так важна.
            </p>
            <p>
              Прежде всего, стирка ковров и ковриков помогает удалить такие
              загрязнения, как бактерии, вирусы, клещи, пыль и пыльцу. Эти
              факторы могут повлиять на наше здоровье, особенно у людей,
              страдающих аллергией и астмой. Регулярная стирка ковров и ковриков
              поможет снизить возникновение аллергических реакций и
              предотвратить распространение инфекционных заболеваний.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Во-вторых, ковры и коврики подвергаются воздействию различных
              типов пятен и грязи, например, от масла, кофе или вина. Со
              временем эти пятна становится все труднее удалить. Регулярная
              стирка поможет удалить эти пятна и сохранить ковер в хорошем
              состоянии в течение более длительного периода времени.
            </p>
            <p>
              В-третьих, регулярная стирка ковров и ковриков поможет сохранить
              их внешний вид и долговечность. Со временем на этих поверхностях
              могут накапливаться загрязнения, что может повлиять на их
              структуру и долговечность. Стирка удаляет эти загрязнения и
              улучшает качество поверхности, что положительно влияет на внешний
              вид и долговечность ковров и ковриков.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              В-четвертых, стирка ковров и ковриков – это тоже вопрос запахов.
              Со временем на этих поверхностях могут скапливаться запахи
              окружающей среды, особенно в местах, где мы курим, готовим или
              выращиваем животных. Регулярная стирка удаляет эти запахи и
              освежает поверхности.
            </p>
            <p>
              Подводя итог, можно сказать, что стирка ковров и ковриков — важное
              занятие, которое влияет на чистоту и гигиену наших домов.
              Регулярная стирка удаляет грязь, пятна и запахи и сохраняет ковры
              в хорошем состоянии в течение более длительного периода времени.
              Поэтому стоит регулярно мыться, чтобы обеспечить чистую и здоровую
              атмосферу в наших домах.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article3Ru() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <Quilon />
      <Telegram />
      <Footer />
    </div>
  );
}
export default Article3Ru;

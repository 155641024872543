import { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article1.jpg';
import quilon2 from '../../../img/articles/article1.2.jpg';
import Telegram from '../../../UI/Viber/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>ET</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/ru/article3">RU</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}
export function Cataloge() {
  const [isScroll, setScroll] = useState(true);

  useEffect(() => {
    if (isScroll) {
      window.scroll({ top: -100, behavior: 'smooth' });
      setScroll(false);
    }
  }, [isScroll]);

  const toggle = () => {
    setScroll(true);
  };
  const toggle2 = () => {
    setScroll(true);
  };

  return (
    <div className="catalogs">
      <h3>Kataloog:</h3>
      <ul>
        <li onClick={toggle}>
          <NavLink to="/article1">
            <span>Madratsite keemiline puhastus</span>
          </NavLink>
        </li>
        <li onClick={toggle2}>
          <NavLink to="/article2">
            <span>Vaipade keemiline puhastus</span>
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/article3">Pehme mööbli keemiline puhastus</NavLink>
        </li>
      </ul>
    </div>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Pehme mööbli keemiline puhastus Tallinn</h2>

            <p>
              Pehme mööbli keemiline puhastus on üks olulisemaid hügieeni
              hoidmisega seotud tegevusi meie kodudes ja töökohtades. Pehme
              mööbel pole mitte ainult koht lõõgastumiseks, vaid on sageli ka
              töö- või söömispinnaks. Seetõttu koguvad nad endasse palju
              mustust, baktereid ja lestasid, mis võivad meie tervist
              negatiivselt mõjutada.
            </p>
            <p>
              Üks olulisemaid põhjuseid pehme mööbli korrapäraseks pesemiseks on
              sellelt mustuse eemaldamine. Pehme mööbel, eriti heledates
              toonides, hakkab kiiresti määrdunud ja kahjustatud välja nägema.
              Selliseid plekke nagu toiduplekid, õliplekid või kuivanud
              toidujäägid on polstri pinnalt väga raske eemaldada. Regulaarne
              keemiline puhastus aitab eemaldada need plekid ja taastada mööbli
              esialgse välimuse.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Teine oluline põhjus pehme mööbli pesemiseks on bakterite ja
              seente kasvu takistamine. Kui pehme mööbli pinnale satub mustus ja
              niiskus, võivad paljuneda seened ja bakterid, mis võivad
              põhjustada haigusi ja allergiaid. Pehme mööbli keemiline puhastus
              aitab neid mikroorganisme eemaldada, mis omakorda avaldab
              positiivset mõju meie tervisele.
            </p>
            <p>
              Trzecim powodem prania mebli tapicerowanych jest pozbycie się
              roztoczy. Roztocza to małe organizmy żyjące w kurzu i brudzie na
              powierzchniach mebli. U wielu osób, zwłaszcza tych, które mają
              problemy z oddychaniem, powodują alergie. Regularne mycie mebli
              tapicerowanych pomoże pozbyć się roztoczy, co z kolei poprawi
              jakość powietrza w naszych domach.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Kokkuvõtteks võib öelda, et pehme mööbli keemiline puhastus on
              oluline hügieeni säilitamiseks meie kodudes ja töökohtades. See
              aitab eemaldada mustuse, bakterid ja lestad, mis võivad meie
              tervist negatiivselt mõjutada. Pehme mööbli regulaarne keemiline
              puhastus aitab säilitada selle välimust ja kvaliteeti pikka aega.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article1() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <Quilon />
      <Telegram />
      <Footer />
    </div>
  );
}
export default Article1;

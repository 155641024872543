import { NavLink } from 'react-router-dom';
import { useProject } from '../../../tools/ProviderContext';
// import css from './Nav.module.css';
import './Nav.css';
import './Nav.css';
function Nav() {
  const { closeBurgerMenu } = useProject();
  return (
    <>
      <nav className="menu">
        <ul className={'menu__list'}>
          <li className={'menu__item'}>
            <NavLink
              to="/ru/main"
              className={({ isActive }) =>
                isActive ? 'menu__link active' : 'menu__link'
              }
              onClick={closeBurgerMenu}
            >
              Главная
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/ru/about"
              onClick={closeBurgerMenu}
              className="menu__link"
            >
              О нас
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/ru/price"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Цены
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/ru/article1"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Статьи
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/contactru"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Контакты
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}
export default Nav;

import { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Price.css';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Telegram from '../../../UI/Viber/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="10px">
          <Button onClick={changeLang} select="select">
            <span>ET</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? (
              <Link to="/ru/price">RU</Link>
            ) : (
              <Link to="/price">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function PriceNotTable() {
  return (
    <>
      <div className="price">
        <div className="price-title">
          <h1>Hinnad</h1>
        </div>
        <div className="price__fon">
          <div className="container container-300px">
            <div className="container__title">
              <p>Pange tähele, et märgitud hinnad on soovituslikud.</p>
              <p>
                Täpsema hinnapakkumise saamiseks tuleb kinnistuga tutvuda ja
                mõlemalt poolt aru saada, milliseid töid vaja läheb. Teie soov,
                meie teostus!
              </p>
            </div>
            <div className="allitems">
              <div className="standartcleaning">
                <h2>Standartne puhastus: </h2>
                <ul>
                  <li>
                    Horisontaalsetelt pindadelt ja mööblilt mustuse eemaldamine
                  </li>
                  <li>
                    Põrandate kuiv- ja märg- ning põrandaliistude puhastus
                  </li>
                  <li>Põrandaliistude märgpuhastus</li>
                  <li>Radiaatorite märgpuhastus väljast</li>
                  <li>Tolmu eemaldamine laua- ja põrandalampidelt</li>
                  <li>Peeglite ja klaaspindade puhastamine</li>
                  <li>Pehme mööbli välispindade tolmuimejaga puhastamine</li>
                  <li>Kodumasinate puhastamine õues</li>
                  <h3>Köögi puhastus:</h3>
                  <li> Köögimööbli fassaadide märgpuhastus </li>
                  <li>Töötasapindade märgpuhastus</li>
                  <li>Külmiku välispinna märgpuhastus</li>
                  <li>Ahju välispinna märgpuhastus</li>
                  <li>Pliidi märgpuhastus</li>
                  <li>Väikeste esemete puhastamine köögis</li>
                  <li>Valamu puhastamine</li>
                  <li>Söögilaua ja toolide märgpuhastus</li>
                  <li>Pistikute, lülitite ja uste puhastamine</li>
                  <li>Radiaatorite märgpuhastus väljast</li>
                  <li>Aknalaudade märgpuhastus</li>
                  <li>Vaipade ja põrandate puhastamine tolmuimejaga</li>
                  <li>
                    Põrandate kuiv- ja märg- ning põrandaliistude puhastus
                  </li>
                  <li>Põrandaliistude märgpuhastus</li>
                  <li>Prügi väljaviimine</li>
                  <li>Nähtavate plekkide eemaldamine seintelt </li>
                  <h3> Vannitoa ja tualeti puhastamine: </h3>
                  <ul>
                    <li>Valamu, vanni ja kraanikausi puhastamine</li>
                    <li>
                      Klaasvaheseinte puhastamine duširuumides ja vannides
                    </li>
                    <li>Segistite puhastamine</li>
                    <li>Peeglite puhastamine</li>
                    <li>Kappide välispindade puhastamine</li>
                    <li>Pistikute, lülitite ja uste puhastamine</li>
                    <li> Vaipade ja põrandate puhastamine tolmuimejaga</li>
                    <li>
                      Põrandate kuiv- ja märg- ning põrandaliistude puhastus
                    </li>
                    <li> Koridori ja esiku koristamine.</li>
                  </ul>
                </ul>
                <div className="about-price">Orienteeruv hind 1,1€ ruutm.</div>
              </div>
              <div className="standartcleaning">
                <h2>Üldpuhastus: </h2>
                <ul>
                  <li>Horisontaalsete mööblipindade kuiv- ja märgpuhastus</li>
                  <li>Nähtavate plekkide eemaldamine seintelt</li>
                  <li>
                    Põrandate kuiv- ja märg- ning põrandaliistude puhastus
                  </li>
                  <li>Põrandaliistude puhastamine</li>
                  <li>Radiaatorite märgpuhastus </li>
                  <li>Tolmu eemaldamine laua- ja põrandalampidelt</li>
                  <li>Peeglite ja klaaspindade puhastamine ja pesemine</li>
                  <li>Pistikupesade, lülitite ja uste puhastamine</li>
                  <li>Kodumasinate puhastamine</li>
                  <li>Märgpuhastus</li>
                  <li>Akende, aknaraamide ja sisemiste nõlvade pesu</li>
                  <li>Pehme mööbli puhastus tolmuimejaga</li>
                  <li>Tolmu eemaldamine lampidelt</li>
                  <li>Kodumasinatelt tolmu eemaldamine</li>
                  <h3>Vannituba/wc: </h3>
                  <li>Valamute, vannide, segistite puhastus ja pesu</li>
                  <li>Klaasvaheseinte pesemine duširuumides ja vannides</li>
                  <li>Kodumasinate puhastamine õues</li>
                  <li>Peeglite puhastus ja pesu</li>
                  <li>Kappide puhastamine</li>
                  <li>Õhupuhastite märgpuhastus</li>
                  <li>Pistikute, lülitite ja uste puhastamine</li>
                  <li>
                    Põrandate kuiv- ja märg- ning põrandaliistude puhastus
                  </li>
                  <li>Uste ja nõlvade pesu</li>
                  <h3>Esik/koridor:</h3>
                  <ul>
                    <li>
                      Laudade, sahtlite, kappide ja muude sisustuselementide
                      puhastamine
                    </li>
                    <li>
                      Põrandate kuiv- ja märg- ning põrandaliistude puhastus
                    </li>
                    <li>Põrandaliistude märgpuhastus</li>
                    <li>Peeglite ja klaaspindade puhastamine</li>
                    <li>Pistikute, lülitite ja uste puhastamine</li>
                    <li>Pehme mööbli puhastus tolmuimejaga</li>
                    <li>Tolmu eemaldamine lampidelt</li>
                  </ul>
                </ul>
                <div className="about-price">Orienteeruv hind 2,5€ ruutm.</div>
              </div>
              <div className="standartcleaning">
                <h2>Pehme mööbli keemiline puhastus: </h2>
                <ul>
                  <li>Diivani keemiline puhastus alates 20 €/koht</li>
                  <li>Väikesed padjad - 4€</li>
                  <li>Suured padjad-5€</li>
                  <li>Nahkdiivani puhastus ja hooldus alates 22 €/koht</li>
                  <li>Vaiba süvapuhastus alates 1,30 €/m2 </li>
                  <li>Vaipade puhastus alates 10 €/m2</li>
                  <li> Kontoritoolide puhastus alates 10.00 €/tk </li>
                  <li> Tekstiiltooli puhastamine - 10 €</li>
                  <li>
                    Madratsite keemiline puhastus (ühekohaline) alates 55 €/tk
                  </li>
                  <li>
                    Madratsite keemiline puhastus (kahekohaline) alates 75 €/tk
                  </li>
                </ul>
              </div>
              <div className="standartcleaning">
                <h2>Akende puhastus: </h2>
                <ul>
                  <li>Aknaklaaside pesu seest ja väljast</li>
                  <li>Aknaraamide ja tihendite puhastamine</li>
                  <li>Aknalaudade puhastus ja pesu</li>
                </ul>
                <div className="pswashwindows">
                  <p>
                    Akende pesemine väljastpoolt on võimalik temperatuuril kuni
                    -3 °C.
                  </p>
                  <p>
                    Parima tulemuse saavutamiseks on parim aknaid pesta kuiva
                    ilmaga.
                  </p>
                  <p>
                    Soovitame aknaid puhastada vähemalt kaks korda aastas, kuid
                    olenevalt hoone asukohast võib vaja minna ka sagedasemat
                    puhastamist.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function PriceRu() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <PriceNotTable />
      <Telegram />
      <Footer />
    </div>
  );
}
export default PriceRu;

import Nav from '../Nav/Nav';
import { useState, useLayoutEffect } from 'react';
import { useProject } from '../../../tools/ProviderContext';
import './Header.css';
import LinkPhone, { PhoneWithIcons } from '../LinkPhone/LinkPone';
import LinkSocial from '../SocialLinks/LinkSocial';
import Logo from '../Logo/Logo';
import BurgerBtnMenu from '../BurgerBtnMenu/BurgerBtnMenu';
import Email from '../Email/Email';

function AddHeader() {
  const [isHeader, setIsHeader] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 550) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  }, [setIsHeader]);

  return (
    <>
      {isHeader ? (
        <>
          <div className="isHeaderPhoneSchedule">
            <PhoneWithIcons />
            <Email />
          </div>
          <LinkSocial />
        </>
      ) : (
        <>
          <LinkPhone />
          <Email />
          <LinkSocial />
        </>
      )}
    </>
  );
}

function Header() {
  const { isMenu, closeMenu, closeBurgerMenu } = useProject();
  const [isScrollUp, setScrollUp] = useState(false);
  const { isBurgerMenu } = useProject();
  const { setIsScroll } = useProject();

  window.addEventListener('scroll', function () {
    let scrolled = window.scrollY;
    if (scrolled > 50) {
      setScrollUp(true);
      setIsScroll(true);
    }
    if (scrolled < 50) {
      setScrollUp(false);
      setIsScroll(false);
    }
  });

  return (
    <>
      <header className={isBurgerMenu ? 'header open' : 'header'}>
        <div
          className={!isScrollUp ? 'header__container-links' : 'header__fixed'}
        >
          <div
            className={
              !isScrollUp
                ? 'container header__links'
                : 'container header__links-scroll'
            }
          >
            <AddHeader />
          </div>
        </div>
        <div className="header__menu">
          <div className="container header__container">
            <Logo />
            <BurgerBtnMenu />
            <Nav />
          </div>
        </div>
        <div
          onClick={() => {
            closeMenu();
            closeBurgerMenu();
          }}
          className={isMenu ? 'backdropMenu' : 'hidden'}
        ></div>
      </header>
    </>
  );
}
export default Header;

import { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article1.jpg';
import quilon2 from '../../../img/articles/article1.2.jpg';
import Telegram from '../../../UI/Viber/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>RU</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/article3">ET</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}
export function Cataloge() {
  const [isScroll, setScroll] = useState(true);

  useEffect(() => {
    if (isScroll) {
      window.scroll({ top: -100, behavior: 'smooth' });
      setScroll(false);
    }
  }, [isScroll]);

  const toggle = () => {
    setScroll(true);
  };
  const toggle2 = () => {
    setScroll(true);
  };

  return (
    <div className="catalogs">
      <h3>Каталог</h3>
      <ul>
        <li onClick={toggle}>
          <NavLink to="/ru/article1">
            <span>Химчистка матрасов</span>
          </NavLink>
        </li>
        <li onClick={toggle2}>
          <NavLink to="/ru/article2">
            <span>Химчистка ковров и ковриков</span>
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/ru/article3">Химчистка мягкой мебели</NavLink>
        </li>
      </ul>
    </div>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Химчистка мягкой мебели Таллинн</h2>

            <p>
              Химчистка мягкой мебели – одно из важнейших мероприятий, связанных
              с поддержанием гигиены в наших домах и на рабочих местах. Мягкая
              мебель – это не только место для отдыха, но и зачастую служит
              поверхностью для работы или приема пищи. Поэтому они собирают
              много грязи, бактерий и клещей, которые могут негативно повлиять
              на наше здоровье.
            </p>
            <p>
              Одна из важнейших причин регулярно стирать мягкую мебель – это
              удаление с нее загрязнений. Мягкая мебель, особенно светлых тонов,
              быстро начинает выглядеть грязной и поврежденной. Такие
              загрязнения, как пищевые пятна, масляные пятна или засохшие
              остатки пищи, очень трудно удалить с поверхности обивки.
              Регулярная стирка поможет удалить эти пятна и вернуть мебели
              первоначальный вид.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Вторая важная причина стирать мягкую мебель – это предотвращение
              размножения бактерий и грибков. При попадании грязи и влаги на
              поверхность мягкой мебели могут размножаться грибки и бактерии,
              которые могут стать причиной заболеваний и аллергии. Стирка мягкой
              мебели поможет удалить эти микроорганизмы, что, в свою очередь,
              окажет положительное влияние на наше здоровье.
            </p>
            <p>
              Третья причина постирать мягкую мебель – избавиться от пылевых
              клещей. Пылевые клещи — это небольшие организмы, живущие в пыли и
              грязи на поверхностях мебели. Они вызывают аллергию у многих
              людей, особенно у тех, у кого есть проблемы с дыханием. Регулярная
              стирка мягкой мебели поможет избавиться от пылевых клещей, что, в
              свою очередь, улучшит качество воздуха в наших домах.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Подводя итог, можно сказать, что стирка мягкой мебели важна для
              поддержания гигиены в наших домах и на рабочих местах. Она
              помогает удалить грязь, бактерии и клещей, которые могут негативно
              повлиять на наше здоровье. Регулярная стирка мягкой мебели поможет
              сохранить ее внешний вид и качество надолго.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article1Ru() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <Quilon />
      <Telegram />
      <Footer />
    </div>
  );
}
export default Article1Ru;

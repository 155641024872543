import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article3.jpg';
import quilon2 from '../../../img/articles/article3.3.jpg';
import { Cataloge } from './1_Article';
import Telegram from '../../../UI/Viber/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>ET</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/ru/article2">RU</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Vaipade ja vaipade keemiline puhastus Tallinn</h2>

            <p>
              Vaipade ja vaipade keemiline puhastus on oluline tegevus, mis
              mõjutab meie kodude puhtust ja hügieeni. Vaatamata regulaarsele
              tolmuimemisele võib nendele pindadele koguneda erinevat tüüpi
              saasteaineid, mis ei mõjuta mitte ainult esteetikat, vaid ka meie
              tervist. Selles artiklis tutvustame mitmeid põhjuseid, miks
              vaipade ja vaipade keemiline puhastus on nii oluline.
            </p>
            <p>
              Esiteks aitab vaipade ja vaipade keemiline puhastus eemaldada
              saasteaineid nagu bakterid, viirused, lestad, tolm ja õietolm.
              Need tegurid võivad mõjutada meie tervist, eriti allergikute ja
              astmahaigete puhul. Vaipade ja vaipade regulaarne keemiline
              puhastus aitab vähendada allergiliste reaktsioonide teket ja
              takistab nakkushaiguste levikut.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Teiseks puutuvad vaibad ja vaibad kokku erinevat tüüpi plekkide ja
              mustusega, nagu õli, kohv või vein. Aja jooksul on neid plekke üha
              raskem eemaldada. Regulaarne keemiline puhastus aitab need plekid
              eemaldada ja hoiab vaipa pikema aja jooksul heas seisukorras.
            </p>
            <p>
              Kolmandaks aitab vaipade ja vaipade regulaarne keemiline puhastus
              säilitada nende välimust ja vastupidavust. Aja jooksul võivad
              nendele pindadele koguneda saasteained, mis võivad mõjutada nende
              struktuuri ja vastupidavust. keemiline puhastus eemaldab need
              plekid ja parandab pinna kvaliteeti, mis mõjutab positiivselt
              vaipade ja vaipade välimust ja vastupidavust.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Neljandaks on vaipade ja vaipade keemiline puhastus ka lõhnade
              asi. Aja jooksul võivad nendele pindadele koguneda
              keskkonnalõhnad, eriti piirkondades, kus me suitsetame, küpsetame
              või kasvatame loomi. Regulaarne keemiline puhastus eemaldab need
              lõhnad ja värskendab pindu.
            </p>
            <p>
              Kokkuvõtteks võib öelda, et vaipade ja vaipade keemiline puhastus
              on oluline tegevus, mis mõjutab meie kodude puhtust ja hügieeni.
              Regulaarne keemiline puhastus eemaldab mustuse, plekid ja lõhnad
              ning hoiab vaibad pikema aja jooksul heas korras. Seetõttu tasub
              regulaarselt pesta, et tagada meie kodudes puhas ja tervislik
              keskkond.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article3() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <Quilon />
      <Telegram />
      <Footer />
    </div>
  );
}
export default Article3;

import { useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { PhoneOnly } from '../1_Header/LinkPhone/LinkPone';
import LinkSocial from '../1_Header/SocialLinks/LinkSocial';
import Email from '../1_Header/Email/Email';

import './Footer.css';

function FooterMenu() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setScrollUp(false);
    }
  }, [isScrollUp]);

  return (
    <nav className="footer-menu">
      <ul className="footer__menu__list">
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/ru/main"
            className="footer__menu__link"
          >
            Главная
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/ru/about"
            className="footer__menu__link"
          >
            O нас
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/ru/price"
            className="footer__menu__link"
          >
            Цены
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/ru/article1"
            className="footer__menu__link"
          >
            Статьи
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/contactru"
            className="footer__menu__link"
          >
            Контакт
          </Link>
        </li>
      </ul>
    </nav>
  );
}
function Footer() {
  const [isHeader, setIsHeader] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 390) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  }, [setIsHeader]);
  return (
    <>
      <div className="footer">
        <div className="container footer__container">
          <div className="footer__main">
            <div className="footer__menu">
              <FooterMenu />
              <div />
              {isHeader ? (
                <>
                  <div className="footer__social-links">
                    <div className="Social">
                      <LinkSocial />
                    </div>
                    <div className="footer-phone header__info">
                      <PhoneOnly />
                      <Email />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="footer__title_phone">
                    <div className="footer-phone header__info">
                      <PhoneOnly />
                      <Email />
                    </div>
                  </div>
                  <div className="footer__social-links">
                    <LinkSocial />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="footer__down">
          <h3>Услуги химчистки | Super Clean | 2024 </h3>
        </div>
      </div>
    </>
  );
}

export default Footer;

import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about.jpg';
import Telegram from '../../../UI/Viber/WhatsappLink';
import './About.css';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>RU</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? <Link to="/about">ET</Link> : <Link to="/about">RU</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function AboutTable() {
  return (
    <div className="about">
      <div className="about-title">
        <h1>О нас</h1>
      </div>
      <div className="container about-container">
        <div className="about__content">
          {/* <h2></h2> */}
          <div className="about__content-text">
            <h2>
              Здравствуйте, уважаемые клиенты, мы клининговая компания "Super
              Clean"
            </h2>

            <p>
              Компания <strong>"Super Clean"</strong> занимает чрезвычайно
              важное место на рынке, поскольку предлагает услуги, необходимые в
              повседневной жизни. Чистка мебели — задача, которую сложно
              выполнить дома, особенно при наличии серьезных загрязнений или
              опасных веществ. Поэтому стоит воспользоваться услугами
              профессионалов, которые знают, как обращаться с каждым видом
              мебели и обеспечить ее правильную чистку.
            </p>
            <p>
              Мы работаем, вы отдыхаете! Чистый дом-хорошее настроение! Команда
              специалистов из Super Clean гарантирует качественную уборку и
              сохранение порядка для твоего дома.(недвижимости)
            </p>
            <p>
              Наша компания занимается профессиональной чисткой мягкой мебели, в
              том числе диванов, стульев, кресел, кроватей и другой мягкой
              мебели. Наши услуги адресованы как индивидуальным клиентам, так и
              компаниям, которым необходимы услуги по чистке мебели в офисах,
              гостиницах, ресторанах и других общественных местах.
            </p>
            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>
            <p>
              Мы используем только самые качественные инструменты и чистящие
              средства, чтобы обеспечить эффективную и безопасную уборку. Наша
              работа основана на индивидуальном подходе к каждому заказу, чтобы
              обеспечить наилучший результат для каждого предмета мебели и
              клиента.
            </p>
            <p>
              Наша компания предлагает широкий спектр услуг, таких как чистка
              поверхностей, удаление пятен, удаление запахов, дезинфекция,
              уборка пылесосом и многое другое. Каждая услуга разрабатывается с
              учетом индивидуальных потребностей клиента для обеспечения
              наилучшего результата.
            </p>
            <p>
              Чистка мягкой мебели может стоить дорого, но наша компания
              предлагает конкурентоспособные цены, которые зависят от размера и
              типа мебели. Наши цены справедливы и прозрачны, а это значит, что
              клиентам не придется беспокоиться о скрытых расходах.
            </p>
            <p>
              Как компания по чистке мебели, мы стремимся обеспечить чистоту,
              безопасность и удовлетворенность наших клиентов нашими услугами.{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function AboutRu() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <ButtomsLang />
        <AboutTable />
        <Telegram />
        <Footer />
      </div>
    </>
  );
}
export default AboutRu;

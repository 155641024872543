import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import foto1 from '../../img/articles/article1.jpg';
import foto2 from '../../img/articles/article2.jpg';
import foto3 from '../../img/articles/article3.jpg';
import './General_img.css';

function Block() {
  return (
    <div className="block">
      <h3>Pehme mööbli keemiline puhastus</h3>
    </div>
  );
}
function BlockOneImgP() {
  return (
    <div>
      <img src={foto1} alt="foto1" className="fotoImg" />
      <p className="special">
        Pehme mööbli pesemine on üks olulisemaid hügieeni hoidmisega seotud
        tegevusi meie kodudes ja töökohtades. Pehme mööbel pole mitte ainult
        koht lõõgastumiseks, vaid on sageli ka töö- või söömispinnaks. Seetõttu
        koguvad nad endasse palju mustust, baktereid ja lestasid, mis võivad
        meie tervist negatiivselt mõjutada...
      </p>
    </div>
  );
}
function BlockOneCheck() {
  return (
    <div className="check">
      <Link to="/article3">Üksikasjalikult...</Link>
    </div>
  );
}
function BlockOneFromArticle1() {
  return (
    <div className="block_1">
      <Block />
      <BlockOneImgP />
      <BlockOneCheck />
    </div>
  );
}
function BlockTwo() {
  return (
    <div className="block">
      <h3>Madratsi keemiline puhastus</h3>
    </div>
  );
}
function BlockTwoImgP() {
  return (
    <div>
      <img src={foto2} alt="foto9" className="fotoImg" />
      <p className="special">
        Madrats on meie magamistoa üks olulisemaid elemente ja samas koht, kus
        veedame umbes kolmandiku oma elust. Seetõttu on oluline hoida meie
        madrats puhas ja hügieeniline. Madratsite regulaarne pesemine on puhtuse
        ja hügieeni säilitamiseks ülioluline ning selles artiklis toome välja
        mitu põhjust, miks see nii oluline on...
      </p>
    </div>
  );
}
function BlockTwoCheck() {
  return (
    <div className="check">
      <Link to="/article1">Üksikasjalikult...</Link>
    </div>
  );
}
function BlockTwoFromArticle2() {
  return (
    <div className="block_2">
      <BlockTwo />
      <BlockTwoImgP />
      <BlockTwoCheck />
    </div>
  );
}
function Block3() {
  return (
    <div className="block">
      <h3>Vaipade ja vaipade keemiline puhastus</h3>
    </div>
  );
}
function BlockThreeImgP() {
  return (
    <div>
      <img src={foto3} alt="foto1" className="fotoImg" />
      <p className="special">
        Vaipade ja vaipade pesemine on oluline tegevus, mis mõjutab meie kodude
        puhtust ja hügieeni. Vaatamata regulaarsele tolmuimemisele võib nendele
        pindadele koguneda erinevat tüüpi saasteaineid, mis ei mõjuta mitte
        ainult esteetikat, vaid ka meie tervist. Selles artiklis tutvustame
        mitmeid põhjuseid, miks vaipade ja vaipade pesemine on nii oluline...
      </p>
    </div>
  );
}
function BlockThreeCheck() {
  return (
    <div className="check">
      <Link to="/article2">Üksikasjalikult...</Link>
    </div>
  );
}
function BlockOneFromArticle3() {
  return (
    <div className="block_3">
      <Block3 />
      <BlockThreeImgP />
      <BlockThreeCheck />
    </div>
  );
}
function SwiperArticles() {
  return (
    <Swiper
      navigation
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={'auto'}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={{ type: 'fraction' }}
      modules={[EffectCoverflow, Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <BlockTwoFromArticle2 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle3 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle1 />
      </SwiperSlide>
      {/* <SwiperSlide></SwiperSlide> */}
    </Swiper>
  );
}
export default SwiperArticles;

import { Link } from 'react-router-dom';
import Img_Facebook from '../../../img/links/facebook2.png';

import './LinkSocial.css';

function LinkSocial() {
  return (
    <div className="header__list-social-media">
      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/KoristusPuhastus"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <img src={Img_Facebook} alt="facebook" />
        </Link>
      </div>
    </div>
  );
}
export default LinkSocial;

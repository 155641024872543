import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article2.jpg';
import quilon2 from '../../../img/articles/article2.2.jpg';
import { Cataloge } from './1_Article';
import Telegram from '../../../UI/Viber/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>ET</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/ru/article1">RU</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Madratsite keemiline puhastus Tallinn</h2>

            <p>
              Madrats on meie magamistoa üks olulisemaid elemente ja samas koht,
              kus veedame umbes kolmandiku oma elust. Seetõttu on oluline hoida
              meie madrats puhas ja hügieeniline. Madratsite regulaarne
              keemiline puhastus on puhtuse ja hügieeni säilitamiseks ülioluline
              ning selles artiklis toome välja mitu põhjust, miks see nii
              oluline on.
            </p>
            <p>
              Esiteks eemaldab madratsite pesu aja jooksul madratsisse kogunevad
              saasteained nagu tolm, lestad, hallitus, bakterid ja viirused.
              Need saasteained võivad põhjustada allergiaid, astmat ja muid
              haigusi ning mõjutada ka une kvaliteeti. Seetõttu on nende
              kahjulike saasteainete eemaldamiseks oluline oma madratsit
              regulaarselt pesta.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Teiseks mõjutab madratsite keemiline puhastus meie madratsi
              esteetikat ja funktsionaalsust. Puhas madrats näeb esteetiliselt
              meeldivam välja ja tunneb end magamistoas paremini. Lisaks võib
              madratsite keemiline puhastus parandada meie une mugavust,
              eemaldades plekid, lõhnad ja muu mustuse, mis võib meie
              unekvaliteeti mõjutada.
            </p>
            <p>
              Kolmandaks võib madratsi keemiline puhastus pikendada selle
              eluiga. Madratsid on kallis investeering, mistõttu on oluline
              tagada nende pikaealisus. Regulaarne keemiline puhastus aitab
              hoida teie madratsi puhtana ja hügieenilisena ning hoiab ära
              kulumise. Tänu sellele saame oma madratsit kauem nautida.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Neljandaks aitab madratsite keemiline puhastus vabaneda
              ebameeldivatest lõhnadest. Madratsid koguvad sageli higi, toidu ja
              muude allikate lõhnu, mida on raske eemaldada. Madratsite
              keemiline puhastus eemaldab need lõhnad, millel on positiivne mõju
              unemugavusele ja õhukvaliteedile meie magamistoas.
            </p>
            <p>
              Kokkuvõtteks võib öelda, et madratsite keemiline puhastus on
              äärmiselt oluline ja seda tuleks teha regulaarselt. Regulaarselt
              pestes hoiame oma madratsi puhta ja hügieenilisena, parandame
              unemugavust, pikendame madratsi eluiga ning väldime kahjulike
              saasteainete teket. Madratsite keemiline puhastus on lihtne ja
              tõhus lahendus, mis võimaldab meil oma madratsit pikka aega
              nautida.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article2() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <Quilon />
      <Telegram />
      <Footer />
    </div>
  );
}
export default Article2;

import './Important.css';
import expert from '../../../img/important/expert.png';
import quality from '../../../img/important/quality-control.png';
import warning from '../../../img/important/hand.png';
import incurence from '../../../img/important/security_11238130.png';

function Important() {
  return (
    <div className="main__important">
      <h2 className="main-important-h2">
        <span>Почему нас выбирают!</span>
      </h2>

      <div className="main-important-blocks">
        <div className="main-important-block">
          <img src={expert} alt="expert" className="expert" />
          <h3>Профессионализм</h3>
          <p>
            Наши высококвалифицированные специалисты гарантируют безупречный
            результат.
          </p>
        </div>
        <div className="main-important-block">
          <img src={quality} alt="expert" className="expert" />
          <h3>Качество</h3>
          <p>
            Мы используем современное оборудование и проверенные методы для
            достижения максимальной чистоты.
          </p>
        </div>
        <div className="main-important-block">
          <img src={warning} alt="expert" className="expert" />
          <h3>Индивидуальный подход</h3>
          <p>Учет уникальных характеристик и требований каждого клиента</p>
        </div>
      </div>
      <div className="main-important-warning">
        <img src={incurence} alt="incurence" />
        <p>
          Уважаемые клиенты, обращаем ваше внимание, что наши услуги полностью
          застрахованы в соответствии с программой страхования ответственности
          клининговой компании. Это обеспечивает вас дополнительной уверенностью
          в нашей профессиональности и гарантирует безопасность ваших интересов
          в случае каких-либо неожиданных ситуаций. Мы ценим ваше доверие и
          стремимся обеспечить качественные услуги, поддерживая наивысшие
          стандарты безопасности и надежности.
        </p>
      </div>
    </div>
  );
}
export default Important;

import './WhatsappLink.css';
import telegram from '../../img/telegram/telegram.png';
import { useLayoutEffect, useState } from 'react';

// function TelegramDesc() {
//   return (
//     <div className="whatsappLink">
//       <a href="https://t.me/37256680448">
//         <img src={telegram} alt="WhatsApp" title="WhatsApp" />
//       </a>
//     </div>
//   );
// }
function TelegramMobile() {
  return (
    <div className="whatsappLink">
      <a href="tg://resolve?domain=37256680448">
        <img src={telegram} alt="WhatsApp" title="WhatsApp" />
      </a>
    </div>
  );
}

function Telegram() {
  const [isTeleg, setIsTeleg] = useState(false);
  useLayoutEffect(() => {
    if (window.innerWidth < 600) {
      setIsTeleg(true);
    } else {
      setIsTeleg(false);
    }
  }, [setIsTeleg]);
  return <>{isTeleg ? <TelegramMobile /> : ''}</>;
}
export default Telegram;
// function Viber() {
//   const { isViber, setIsViber } = useProject();
//   useLayoutEffect(() => {
//     if (window.innerWidth < 600) {
//       setIsViber(true);
//     } else {
//       setIsViber(false);
//     }
//   }, [setIsViber]);

//   return (
//     <>
//       <div className={isViber ? 'whatsappLink' : 'hidden'}>
//         <Link to="viber://chat?number=37256680448">
//           <img src={viber} alt="Viber" title="Viber" />
//         </Link>
//       </div>
//     </>
//   );
// }
